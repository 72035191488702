@import "styles/_colors";
@import "styles/_fonts";
@import "styles/_z-index";

.header {
  background: linear-gradient(
    122.57deg,
    $color-primary 30.51%,
    $color-secondary 155%
  );
  height: 60px;
}

@media (min-width: 768px) {
  .header {
    height: auto;
  }
}

.content {
  display: flex;
  height: 100%;
  margin: 0 auto;
  max-width: 1400px;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .content {
    padding: 25px;
  }
}

.logo {
  display: block;
  padding: 10px 15px;
  width: 130px;
}

.logo:hover {
  filter: brightness(0.1);
}

.logo:focus:not(.focus-visible) {
  outline: 0;
}

@media (min-width: 768px) {
  .logo {
    width: 200px;
  }
}

@media (min-width: 768px) {
  .logo {
    padding: initial;
  }
}

.hamburger {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 0 auto;
  padding: 4px 18px;

  @media (min-width: 768px) {
    display: none;
  }
}

.line {
  background: $color-white;
  display: block;
  height: 2px;
  margin: 3px auto;
  transition: all 0.2s ease;
  width: 24px;
}

.hamburger.isActive .line:nth-child(2) {
  opacity: 0;
}

.hamburger.isActive .line:nth-child(1) {
  transform-origin: top;
  transform: translateY(8px) rotate(45deg);
}

.hamburger.isActive .line:nth-child(3) {
  transform-origin: bottom;
  transform: translateY(-9px) rotate(-45deg);
}

.nav {
  @include z-index(menu);

  background: linear-gradient(
    122.57deg,
    $color-primary 30%,
    $color-secondary 155%
  );
  min-height: calc(100vh - 60px);
  opacity: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  text-align: right;
  top: 60px;
  transform: translateX(100%);
  transition: all 0.2s ease;
  width: 100%;
  padding-top: 20px;

  @media (min-width: 768px) {
    align-items: center;
    background: none;
    display: flex;
    min-height: initial;
    opacity: 1;
    position: static;
    transform: translateX(0);
    transition: initial;
    width: initial;
    padding-top: 0;
  }
}

.nav.open {
  opacity: 1;
  transform: translateX(0);

  @media (min-width: 768px) {
    transform: initial;
  }
}

.nav ul {
  list-style: none;
  margin: 0;
  padding: 0;

  @media (min-width: 768px) {
    display: flex;
  }
}

.nav a {
  font-family: "Bebas Neue";
  color: $color-white;
  display: block;
  font-size: 36px;
  margin: 10px 0;
  padding: 10px 20px;
  text-decoration: none;
  position: relative;

  @media (min-width: 768px) {
    font-size: 20px;
    font-family: $font-system;
  }
}

.link:hover span,
.link.active span {
  color: $color-secondary;
}

@media (min-width: 768px) {
  .nav a:before {
    background: $color-white;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    transform: scaleX(0);
    transition: transform 0.5s ease;
    width: 100%;
  }

  .nav a:hover {
    color: $color-white;
  }

  .nav a:hover:before {
    transform: scaleX(1);
  }
}

.nav .active {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .nav .active {
    font-weight: bold;
    text-decoration: none;
  }

  .nav a.active:hover:before {
    display: none;
  }
}
