@import "styles/_colors.scss";
@import "styles/_fonts.scss";

$width: 3px;
$size: 40px;

.loading {
  display: block;
  height: $size;
  left: calc(50% - #{$size / 2});
  margin: 20px auto;
  position: absolute;
  top: calc(50% - 40px);
  width: $size;
}

.loading div {
  animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $color-primary transparent transparent transparent;
  border-radius: 50%;
  border-style: solid;
  border-width: $width;
  box-sizing: border-box;
  display: block;
  height: #{$size - ($width * 2)};
  margin: $width;
  position: absolute;
  width: #{$size - ($width * 2)};
}

.loading div:nth-child(1) {
  animation-delay: -0.45s;
}

.loading div:nth-child(2) {
  animation-delay: -0.3s;
}

.loading div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
