@font-face {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("/fonts/bebas-neue-v8-latin-regular.woff2") format("woff2"),
    url("/fonts/bebas-neue-v8-latin-regular.woff") format("woff");
}

$font-system: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
  Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; /* stylelint-disable-line value-keyword-case */

$s: 16px;
$m: 18px;
$l: 20px;
$xl: 22px;
$xxl: 32px;
