@import-normalize;

@import "styles/_colors";
@import "styles/_fonts";

body {
  color: $color-black;
  font-size: 16px;
  line-height: 1.45;
  font-family: $font-system;
}

@media (min-width: 768px) {
  body {
    font-size: 18px;
  }
}

a {
  color: $color-black;
  text-decoration: underline;
}

a:hover {
  color: $color-black;
}

p {
  max-width: 700px;
  margin: 0 0 20px;
}

img {
  max-width: 100%;
}

h1 {
  display: inline-block;
  font-family: "Bebas Neue";
  font-size: 32px;
  font-weight: unset;
  line-height: 1.1;
  margin: 40px 0 20px;
  max-width: 470px;
  background: -webkit-linear-gradient(
    300deg,
    $color-primary 40%,
    $color-secondary 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (min-width: 768px) {
  h1 {
    font-size: 64px;
    margin-top: 80px;
    margin-bottom: 40px;
    max-width: initial;
  }
}

h2 {
  display: inline-block;
  font-family: "Bebas Neue";
  font-weight: normal;
  margin: 0;
  font-size: 26px;
  background: -webkit-linear-gradient(
    300deg,
    $color-primary 40%,
    $color-secondary 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (min-width: 768px) {
  h2 {
    font-size: 30px;
    margin: 20px 0 0;
  }
}

.app {
  min-height: 100vh;
  overflow: hidden;
  position: relative;
}

.container {
  max-width: 770px;
  margin: 0 auto;
  padding: 0 20px;
}
